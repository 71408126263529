.chooseUs-container {
    position: relative;
    height: 80vh;
    width: 100vw;
    overflow: visible;
    background-size: cover;
    background-image: url(../../../public/OurApproach.png);
    background-position: center;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 10%;
    padding-bottom: 10vh;
    /* margin-bottom: 10vh; */
    background-color: #1c202f;
    color: white;
    border-top: 0.5px solid #d9d9d9;

}
.chooseUs-content{
    display: flex;
    flex-direction: row;
    width: 80%;
    /* padding-left: 10%; */
    justify-content: space-around;
    align-items: center;
    margin-bottom: 10%;

}
.chooseUs-heading {
    font-family: 'Playfair Display', serif;
    font-size: 3rem;
    /* margin-bottom: 5%; */
    text-align: left;
    margin-right: 10%;
}

.chooseUs-description{
    font-size: 1.2rem;
    text-align: justify;
    width: 40%;
}

.text-highlight {
    color: #d61525;
}

.chooseUs-image, .chooseUs-image-mob {
    max-width: 100%;
    height: auto;
    display: none; /* Both images are initially set to not display */
    margin: 0 auto;
}

@media (min-width: 600px) {
    .chooseUs-image {
        display: block; /* Display the desktop image on screens wider than 600px */
    }
    .chooseUs-image-mob {
        display: none; /* Ensure the mobile image is hidden on wider screens */
    }
}

@media (max-width: 599px) {
    .chooseUs-container {
        /* margin-bottom: 5vh; */
        height: auto;
    }
    .chooseUs-content{
        display: flex;
        flex-direction: column;
        width: 90%;
    }
    .chooseUs-description{
        margin-top: 10%;
        width: 100%;
        font-size: 1rem;
    }
    .chooseUs-heading {
        font-size: 2rem; /* Adjust font size for smaller screens */
        text-align: left; /* Optional: Change alignment to center for smaller screens */
        margin-right: 0%; /* Adjust margin for smaller screens */
    }
    .chooseUs-image {
        display: none; /* Hide the desktop image on smaller screens */
    }
    .chooseUs-image-mob {
        display: block; /* Display the mobile image on screens 599px wide or less */
    }
}
