.home-container {
  position: relative;
  height: 100vh; /* Ensure the container is always full viewport height */
  overflow: hidden;
  min-height: auto;
  width: 100vw;
  background-image: url(../../../public/LandingBg.png);
  background-size: cover; /* Ensures the background image covers the entire container */
    background-position: center; /* Center the background image */
    background-repeat: no-repeat; /* Prevent the image from repeating */
    background-color: #1c202f;
    justify-content: center;
    align-items: center;
}

.content {
  position: relative;
  z-index: 10; 
  color: white;
  text-align: center;
  padding-top: 12%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.heading{
  font-size: 3.5rem;
  font-family: 'Playfair Display', serif;
  width: 60%;
  text-align: center;
}

.description{
  font-size: 1.2rem;
  font-weight: normal;
  text-align: center;
  /* max-width: 80%; */
  width: 60%;
}

.subHeading{
  font-weight: bold;
  font-size: 2vh;
  margin-top: 8%;
}
.company-logo1{
  width: 5%;
  margin-top: 4%;
}

.ctaBtn{
  background-color: #9661d0 !important;
}

/* Media query for screens smaller than 600px */
@media (max-width: 599px) {
  .home-container {
    background-image: none;
    background-color: #1c202f;
    position: relative;
    overflow: hidden;
    height: auto;
    padding-bottom: 5vh;
    background-size: cover; /* Ensures the background image covers the entire container */
    background-position: center; /* Center the background image */
    background-repeat: no-repeat; /* Prevent the image from repeating */
  }
  .heading{
    font-size: 2.5rem;
    text-align: center;
    width: 95%;
  }
  .content{
    display: flex;
    flex-direction: column;
    max-width: 100%;
    justify-content: center;
    align-items: center;
  }
  .description{
    font-size: 1rem;
    font-weight: normal;
    text-align: center;
    margin-bottom: 5vh;
    width: 90%;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  }
  .subHeading{
    display: none ;
  }
  .company-logo1{
    width: 30%;
    margin-top: 4%;
  }
}
