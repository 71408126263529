.whatWeDo-container {
    position: relative;
    height: 80vh;
    width: 100vw;
    overflow: visible;
    background-size: cover;
    background-image: url(../../../public/WhatWeDoBg.png);
    background-position: center;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: #1c202f;
    color: white;
}
.whatWeDo-content{
    display: flex;
    flex-direction: column;
    width: 40%;
    padding-left: 10%;
    justify-content: center;

}
.whatWeDo-heading {
    font-family: 'Playfair Display', serif;
    font-size: 4rem;
    margin-bottom: 5%;
    text-align: left;
    margin-right: 10%;
}

.whatWeDo-description{
    font-size: 1.2rem;
    text-align: justify;
}

.text-highlight {
    color: #d61525;
}

.whatWeDo-image {
    width: 30%;
    height: auto;
    margin: 0 auto;
}

@media (max-width: 599px) {
    .whatWeDo-container {
        /* margin-bottom: 5vh; */
        height: auto;
        flex-direction: column;
    }
    .whatWeDo-content{
        width: 80%;
        padding-left: 0%;
        text-align: center;
        margin-top: 10%;
    }
    .whatWeDo-description{
        font-size: 1rem;
        margin-bottom: 10%;
        text-align: center;
    }
    .whatWeDo-heading {
        font-size: 2rem; /* Adjust font size for smaller screens */
        text-align: center; /* Optional: Change alignment to center for smaller screens */
        margin-right: 0%;
    }
    .whatWeDo-image {
       width: 95%;
    }
}
