.overview-container {
    position: relative;
    height: 80vh;
    width: 100vw;
    overflow: visible;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding-top: 5%;
}
.overview-content{
    display: flex;
    flex-direction: column;
    max-width: 40%;
    padding-left: 10%;
    justify-content: center;

}
.overview-heading {
    font-family: 'Playfair Display', serif;
    font-size: 4rem;
    margin-bottom: 8%;
    text-align: left;
    margin-right: 10%;
}

.overview-description{
    font-size: 1.5rem;
    text-align: justify;
}

.text-highlight {
    color: #d61525;
}

.overview-image {
    width: 25%;
    height: auto;
    margin: 0 auto;
}

@media (max-width: 599px) {
    .overview-container {
        margin-bottom: 5vh;
        height: auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .overview-content{
        max-width: 90%;
        padding-left: 0%;
    }
    .overview-heading {
        font-size: 2rem; 
        text-align: center; 
    }
    .overview-description{
        font-size: 1rem;
    }
    .overview-image{
        width: 70%;
        margin-top: 2rem;
    }
} 
