.contactUs-container {
    position: relative;
    height: 100vh;
    width: 100vw;
    overflow: visible;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 5%;
    background-color: #1c202f;
    color: #ffffff;
}
.contactUs-content{
    display: flex;
    flex-direction: column;
    width: 70%;
    justify-content: center;
    align-items: center;
    margin-bottom: 5%;

}
.contactUs-heading {
    font-family: 'Playfair Display', serif;
    font-size: 3.5rem;
    text-align: left;
}

.contactUs-description{
    margin-top: 3%;
    font-size: 1.2rem;
    text-align: center;
}

.contactUs-description2{
    margin-top: 3%;
    font-size: 1.2rem;
    text-align: center;
}

.text-highlight {
    color: #d61525;
}

.contactUs-image{
    width: 35%;
    height: auto;
    margin: 0 auto;
}

@media (max-width: 599px) {
    .contactUs-container {
        /* margin-bottom: 5vh; */
        height: auto;
    }
    .contactUs-content{
        width: 90%;
    }
    .contactUs-heading {
        font-size: 2rem; /* Adjust font size for smaller screens */
        text-align: center; /* Optional: Change alignment to center for smaller screens */
        margin-right: 5%; /* Adjust margin for smaller screens */
    }

    .contactUs-description{
        font-size: 1rem;
        margin: 10% 0% 10% 0%;
    }
    .contactUs-image {
        width: 90%;
    }
    .contactUs-description2{
        font-size: 1rem;
        margin: 5vh 0vh 5vh 0vh;
        width: 80%;
    }
}
